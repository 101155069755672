import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Button, Header } from 'semantic-ui-react';
import './App.css';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <div className="Welcome">
            <div className="Welcome-container">
              <div>
                <Header size="large" color="green">
                  Welcome to Recovery Land
                </Header>
                <Header size="tiny" style={{ color: '#f5f5ef', marginTop: 0 }}>
                  Where you realize your full potential
                </Header>
              </div>

              <section>
                <Button size="massive" primary fluid>
                  Enter
                </Button>
              </section>

              <section>
                <p>Already in recovery?</p>
                <Button inverted fluid size="big">
                  Welcome Back
                </Button>
              </section>
            </div>
          </div>
        </Route>
        <Router path="/justin/2020-07-04">
          <div className="centered">
            <video controls autoplay muted={false} name="media">
              <source
                src="https://nula.sfo2.digitaloceanspaces.com/HealthierInitiatives/Clips/Contentment%20-%20Paige%20-%20Mixdown.mp3"
                type="audio/mpeg"
              />
            </video>
          </div>
        </Router>
      </Switch>
    </Router>
  );
}

export default App;
